












































import DraftActions from '@/builder/mixins/DraftActions'
import { DraftActionsType } from '@/calendesk/models/DraftActionsType'
import mixins from 'vue-typed-mixins'
import PagesSingleSelect from '@/builder/sidebar/sidebarDetails/components/PageSingleSelect.vue'
import { DraftConfigurationType, SectionsSectionType } from '@/calendesk/models/BuilderTypes'
import Page from '@/calendesk/models/DTO/Response/Page'
export default mixins(DraftActions).extend({
  name: 'ExternalConfiguration',
  components: { PagesSingleSelect },
  data () {
    return {
      tools: [
        DraftConfigurationType.TEXT,
        DraftConfigurationType.LONG_TEXT,
        DraftConfigurationType.CHECKBOX,
        DraftConfigurationType.PAGE_ID
      ],
      mode: DraftActionsType.EXTERNAL_CONFIGURATION,
      draftConfigurationType: DraftConfigurationType,
      sectionsSectionType: SectionsSectionType
    }
  },
  methods: {
    updateSelectedPage (key: string, page: Page | null) {
      if (page) {
        this.updateConfigurationProperty(key, page.uuid)
      } else {
        this.updateConfigurationProperty(key, null)
      }
    }
  }
})
